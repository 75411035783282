<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center style="background-color: #eefff2">
      <v-flex xs11 sm10 text-left pt-10 pb-4>
        <span
          style="font-family: poppinsmedium; font-size: 25px; color: #000000"
          >Profile</span
        >
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogProfile" width="700px" persistent>
      <v-layout wrap px-4 justify-end style="background-color: white">
        <v-flex xs12 text-left align-self-center py-10 px-5>
          <span
            style="font-family: poppinsmedium; font-size: 25px; color: #000000"
            >Edit Profile</span
          >
          <v-layout wrap pt-2>
            <v-flex xs12 text-left py-2>
              <span style="font-family: opensansregular; font-size: 14px"
                >Full name</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.name"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 text-left>
              <span style="font-family: opensansregular; font-size: 14px"
                >Phone</span
              >
              <v-layout wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    ref="phone"
                    color="#717171"
                    placeholder="Mobile number"
                    outlined
                    dense
                    type="number"
                    v-model="profile.phone"
                  >
                    <template v-slot:prepend-inner>
                      <v-layout justify-center>
                        <v-flex pt-1 class="align-self-center">
                          <span
                            style="
                              font-family: poppinsregular;
                              font-size: 15px;
                              color: #000;
                            "
                            >{{ profile.countryCode }}</span
                          >
                        </v-flex>
                        <v-flex pt-1 pl-2 xs1>
                          <v-divider vertical></v-divider>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-layout wrap justify-start v-if="profile.gender">
              <v-flex text-left>
                <span style="font-family: opensansregular; font-size: 14px"
                  >Gender</span
                >
              </v-flex>
              <v-flex style="font-family: opensansregular; font-size: 14px">
                <v-radio-group v-model="profile.gender" mandatory>
                  <v-layout wrap>
                    <v-flex>
                      <v-radio small label="Male" value="Male"></v-radio>
                    </v-flex>
                    <v-flex>
                      <v-radio label="Female" value="Female"></v-radio>
                    </v-flex>
                  </v-layout>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <span style="font-family: opensansregular; font-size: 14px"
                >Date of birth</span
              >
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="profile.dateofBirth"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="profile.dateofBirth"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <span style="font-family: poppinsmedium; font-size: 18px"
                >Address</span
              >
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 pr-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >Country</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.country"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >State</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.state"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 pr-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >City</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.city"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >Street name</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.streetName"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 pr-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >Street number</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.streetNumber"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left py-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >Zip code</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.zipCode"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm12 text-left py-2 v-if="profile.userAddress">
              <span style="font-family: opensansregular; font-size: 14px"
                >House number and name</span
              >
              <v-text-field
                outlined
                dense
                v-model="profile.userAddress.houseNumberAndName"
                :hide-details="true"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-end pt-5>
            <v-flex xs12 sm2 text-right>
              <v-btn dark color="red" @click="dialogProfile = false">
                <span style="text-transform: none; font-family: opensansregular"
                  >close</span
                >
              </v-btn>
            </v-flex>
            <v-flex xs12 sm2 text-right>
              <v-btn dark color="#3CB043" @click="editProfile()">
                <span style="text-transform: none; font-family: opensansregular"
                  >Save</span
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="dialogChangePassword" width="700px" persistent>
      <v-layout wrap px-4 justify-end style="background-color: white">
        <v-flex xs12 text-left align-self-center py-10 px-5>
          <span
            style="font-family: poppinsmedium; font-size: 25px; color: #000000"
            >Change Password</span
          >
          <v-layout wrap pt-2>
            <v-flex xs12 text-left pt-5>
              <span style="font-family: opensansregular; font-size: 14px"
                >Old password</span
              >
              <v-text-field
                outlined
                dense
                v-model="oldPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm12 text-left>
              <span style="font-family: opensansregular; font-size: 14px"
                >New password</span
              >
              <v-text-field
                :rules="[rules.required, rules.min]"
                v-model="newPassword"
                outlined
                dense
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm12 text-left>
              <span style="font-family: opensansregular; font-size: 14px"
                >Confirm password</span
              >
              <v-text-field
                outlined
                dense
                :rules="[rules.required, rules.min, rules.check]"
                v-model="confirmPassword"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                @click:append="show3 = !show3"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-end pt-5>
            <v-flex xs12 sm2 text-right>
              <v-btn dark color="red" @click="dialogChangePassword = false">
                <span style="text-transform: none; font-family: opensansregular"
                  >close</span
                >
              </v-btn>
            </v-flex>
            <v-flex xs12 sm2 text-right>
              <v-btn dark color="#3CB043" @click="changePassword()">
                <span style="text-transform: none; font-family: opensansregular"
                  >Update</span
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-layout wrap justify-center py-10>
      <v-flex xs12 sm10>
        <v-layout wrap justify-center>
          <v-flex xs12 sm3 md3>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-card outlined color="#F5F7F8">
                  <v-layout wrap justify-center py-10 px-5
                    ><v-flex xs12 v-if="profile.name">
                      <v-avatar size="100" :color="getRandomColor()">
                        <span
                          style="
                            color: #fff;
                            font-family: poppinsmedium;
                            font-size: 40px;
                          "
                          >{{ profile.name.charAt(0).toUpperCase() }}</span
                        >
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 pt-4>
                      <span style="font-family: poppinsmedium">{{
                        profile.name
                      }}</span>
                      <br />
                      <span style="font-family: poppinsregular">{{
                        profile.userUniqueId
                      }}</span>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: #474343;
                          font-size: 13px;
                        "
                        >{{ profile.email }}</span
                      >
                    </v-flex>
                    <v-flex xs12 sm6 md4 pt-4>
                      <v-btn
                        color="#3CB043"
                        block
                        @click="dialogProfile = true"
                      >
                        <span
                          style="
                            font-family: opensansregular;
                            color: #ffffff;
                            font-size: 14px;
                            text-transform: none;
                          "
                          >Edit
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-10>
              <v-flex xs12>
                <v-card outlined color="#F5F7F8">
                  <v-layout wrap justify-center py-5 px-2
                    ><v-flex xs12 text-left>
                      <span style="font-family: poppinsmedium"
                        >Personal Information</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12><v-divider></v-divider></v-flex>
                  </v-layout>
                  <v-layout wrap justify-center py-5>
                    <v-flex xs12 sm10 text-left>
                      <v-layout wrap v-if="profile.dateofBirth">
                        <v-flex xs2 pt-2>
                          <v-icon>mdi-calendar-range</v-icon>
                        </v-flex>
                        <v-flex xs10>
                          <span
                            style="
                              color: #797979;
                              font-family: poppinsregular;
                              font-size: 12px;
                            "
                            >Date of brith</span
                          >
                          <br />
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            >{{ formatDate(profile.dateofBirth) }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap py-5 v-if="profile.phone">
                        <v-flex xs2 pt-2>
                          <v-icon>mdi-phone-outline</v-icon>
                        </v-flex>
                        <v-flex xs10>
                          <span
                            style="
                              color: #797979;
                              font-family: poppinsregular;
                              font-size: 12px;
                            "
                            >Phone number</span
                          >
                          <br />
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            >{{ profile.countryCode }} {{ profile.phone }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap v-if="profile.userAddress">
                        <v-flex xs2 pt-2>
                          <v-icon>mdi-home-outline</v-icon>
                        </v-flex>
                        <v-flex xs10>
                          <span
                            style="
                              color: #797979;
                              font-family: poppinsregular;
                              font-size: 12px;
                            "
                            >Address</span
                          >
                          <br />
                          <span
                            v-if="profile.userAddress.houseNumberAndName"
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            >{{ profile.userAddress.houseNumberAndName }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.streetName"
                          >
                            {{ profile.userAddress.streetName }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.streetNumber"
                          >
                            {{ profile.userAddress.streetNumber }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.zipCode"
                          >
                            {{ profile.userAddress.zipCode }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.city"
                          >
                            {{ profile.userAddress.city }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.state"
                          >
                            {{ profile.userAddress.state }},
                          </span>
                          <span
                            style="
                              color: #363636;
                              font-family: poppinsmedium;
                              font-size: 14px;
                            "
                            v-if="profile.userAddress.country"
                          >
                            {{ profile.userAddress.country }}
                          </span>
                        </v-flex>
                        <v-flex xs12 pt-5>
                          <v-btn
                            color="#3CB043"
                            block
                            @click="dialogChangePassword = true"
                          >
                            <span
                              style="
                                font-family: opensansregular;
                                color: #ffffff;
                                font-size: 14px;
                                text-transform: none;
                              "
                              >Change Password
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm9 md9>
            <v-layout wrap px-5 justify-center>
              <v-flex xs12 pb-5 text-center>
                <v-card outlined>
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm6 pa-2>
                      <v-card outlined>
                        <v-layout wrap px-10 py-7>
                          <v-flex xs2>
                            <v-img
                              src="./../../assets/images/icon1.png"
                            ></v-img>
                          </v-flex>
                          <v-flex xs10 pl-5 text-left>
                            <span
                              style="
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Courses Enrolled</span
                            >
                            <br />
                            <span
                              style="
                                color: #000000;
                                font-family: poppinsbold;
                                font-size: 20px;
                              "
                              >{{ dashBoard.enrolledCourses }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2>
                      <v-card outlined>
                        <v-layout wrap px-10 py-7>
                          <v-flex xs2>
                            <v-img
                              src="./../../assets/images/icon2.png"
                            ></v-img>
                          </v-flex>
                          <v-flex xs10 pl-5 text-left>
                            <span
                              style="
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Tests Attended</span
                            >
                            <br />
                            <span
                              style="
                                color: #000000;
                                font-family: poppinsbold;
                                font-size: 20px;
                              "
                              >{{ dashBoard.examAttended }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2>
                      <v-card outlined>
                        <v-layout wrap px-10 py-7>
                          <v-flex xs2>
                            <v-img
                              src="./../../assets/images/icon4.png"
                            ></v-img>
                          </v-flex>
                          <v-flex xs10 pl-5 text-left>
                            <span
                              style="
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Certifications Recived</span
                            >
                            <br />
                            <span
                              style="
                                color: #000000;
                                font-family: poppinsbold;
                                font-size: 20px;
                              "
                              >{{ dashBoard.certificationsRecived }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap px-5 justify-center>
              <v-flex xs12 pb-10 text-left>
                <v-layout wrap>
                  <v-flex xs12 sm6 pb-5>
                    <span
                      style="
                        color: #000000;
                        font-family: poppinsbold;
                        font-size: 25px;
                      "
                      >Exam Summary</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 text-right pb-5 v-if="exam.length > 0">
                    <v-btn color="#3CB043" :to="'/examDetails'" dark>
                      <span style="font-family: poppinssemibold"
                        >View all exams</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-layout wrap justify-center v-if="exam.length > 0">
                    <v-flex xs12 v-for="(item, i) in exam" :key="i" py-2>
                      <v-card outlined>
                        <v-layout wrap pa-2 py-5>
                          <v-flex>
                            <span
                              style="
                                font-size: 16px;
                                font-family: poppinsmedium;
                              "
                              >{{ item.examAttendedCourseId.courseName }}</span
                            >
                          </v-flex>
                          <v-flex>
                            <span
                              style="
                                font-size: 14px;
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >MCQ</span
                            >
                            <br />
                            <span
                              style="font-family: poppinsmedium; color: #3cb043"
                              >{{ item.markAttainedFromMCQ }}</span
                            >
                          </v-flex>
                          <v-flex>
                            <span
                              style="
                                font-size: 14px;
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >True/False</span
                            >
                            <br />
                            <span
                              style="font-family: poppinsmedium; color: #3cb043"
                              >{{ item.markAttainedFromTrueOrFalse }}</span
                            >
                          </v-flex>
                          <v-flex v-if="item.isAllQuestionsEvaluated == true">
                            <span
                              style="
                                font-size: 14px;
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Essay</span
                            >
                            <br />
                            <span
                              style="font-family: poppinsmedium; color: #3cb043"
                              >{{ item.markAttainedFromEassy }}</span
                            >
                          </v-flex>
                          <v-flex>
                            <span
                              style="
                                font-size: 14px;
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Attempt</span
                            >
                            <br />
                            <span
                              style="font-family: poppinsmedium; color: #3cb043"
                              >{{ item.numberOfAttempts }}</span
                            >
                          </v-flex>
                          <v-flex v-if="item.isAllQuestionsEvaluated == false">
                            <span
                              style="
                                font-size: 14px;
                                color: #5e5f5f;
                                font-family: poppinsregular;
                              "
                              >Status</span
                            >
                            <br />
                            <span style="color: red; font-family: poppinsmedium"
                              >Pending for evaluation</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center v-else>
                    <v-flex xs12 py-16 text-center>
                      <span style="font-family: poppinsbold; font-size: 25px"
                        >No Data Found</span
                      >
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      fab: true,
      slideNo: 0,
      slideInterval: 4000,
      profile: "",
      dialogProfile: false,
      dialogChangePassword: false,
      show1: false,
      show2: false,
      show3: false,
      gender: "",
      name: "",
      phone: "",
      value: "",
      menu1: false,
      date: new Date(),
      dob: null,
      snackbar: false,
      msg: null,
      address: {
        state: "",
        country: "",
        city: "",
        streetName: "",
        streetNumber: "",
        houseNumberAndName: "",
        zipCode: "",
      },
      exam: [],
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      dashBoard: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
        pincode: (value) => value.length == 6 || "Please provide valid pincode",
        otp: (value) => value.length == 4 || "Please provide valid otp",
        check: (confirmation) =>
          confirmation === this.newPassword || "Passwords do not match",
      },
    };
  },
  computed: {
    formattedValue() {
      return "+" + this.value;
    },
  },
  beforeMount() {
    this.getData();
    this.getDashboard();
    this.getExam();
  },

  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/profile",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.profile = response.data.user;
          this.profile.dateofBirth = this.profile.dateofBirth.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getExam() {
      this.appLoading = true;
      axios({
        url: "/exam/list/user",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.exam = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getDashboard() {
      this.appLoading = true;
      axios({
        url: "/user/dashBoard",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dashBoard = response.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editProfile() {
      this.appLoading = true;
      axios({
        url: "/user/edit/profile",
        method: "POST",
        data: {
          name: this.profile.name,
          phone: this.profile.phone,
          email: this.profile.email,
          gender: this.profile.gender,
          dob: this.profile.dateofBirth,
          countryCode: this.profile.countryCode,
          address: {
            country: this.profile.userAddress.country,
            state: this.profile.userAddress.state,
            city: this.profile.userAddress.city,
            streetName: this.profile.userAddress.streetName,
            streetNumber: this.profile.userAddress.streetNumber,
            zipCode: this.profile.userAddress.zipCode,
            houseNumberAndName: this.profile.userAddress.houseNumberAndName,
          },
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.snackbar = true;
            this.dialogProfile = false;
            this.getData();
            (this.name = ""), (this.phone = ""), (this.email = "");
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changePassword() {
      this.appLoading = true;
      axios({
        url: "/user/change/password",
        method: "POST",
        data: {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.snackbar = true;
            this.dialogChangePassword = false;
            (this.oldPassword = ""),
              (this.newPassword = ""),
              (this.confirmPassword = "");
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      //   var hours = dt.getHours();
      //   var minutes = dt.getMinutes();
      dt = dt.toString();
      //   var ampm = hours >= 12 ? "pm" : "am";
      //   hours = hours % 12;
      //   hours = hours ? hours : 12;
      //   minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year + " ";
      return strTime;
    },
  },
};
</script>